.family-container{
    height: 1100px;
    background-color: #252422;
    border: none;
    margin-top: -5px;
}

.family-container-layer{
    background-color: rgba(9, 9, 9, 0.66);
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 500px;
    height: 1100px;
}

.hat1{
    position: absolute;
    top: 410px;
    height: 50px;
    left: 815px;
}

.hat2{
    position: absolute;
    top: 450px;
    left: 670px;
    height: 50px;
}

.heart-on-top{
    display: none;
    position: absolute;
    top: 180px;
    height: 50px;
    left: 0;
    right: 0;
    margin: auto;
}


.family-heading-top{
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    /*width: fit-content;*/
    top: 550px;
    z-index: 5;
    color: #f2f2eb;
    font-family: KumbhSans;
    font-weight: 100;
    margin-top: 140px;
    font-size: 50px;
}

.citate-about-us {
    font-size: 24px;
    transition: opacity 0.5s ease-in-out; /* Smooth transition effect */
    opacity: 1; /* Default visibility */
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.slider-container{
    display: none;
}



/*.bg-overlay{*/
/*   z-index: 4;*/
/*   background-color: rgba(37, 36, 34, 0.54);*/
/*    position: absolute;*/
/*    height: 1000px;*/
/*    top: 0;*/
/*    width: 100%;*/
/*}*/

.top-black-screen{
    background-image: url('../assets/product-images-webp/imimanancaRAMII.webp');
    background-position: 40% 100%;
    background-size: cover;
    height: 900px;
    margin-top: 50px;
    width: 100%;
}

.family-vines{
    margin-top: -75px;
    position: absolute;
    z-index: 3;
    width: 100%;
    background-repeat: no-repeat;
}

.overlay {
    position: absolute;
    top: 1100px;
    height: 800px;
    width: 1000px;
    background-color: rgba(128, 128, 128, 0.5); /* Gray with 50% opacity */
    z-index: 1; /* Ensure the overlay is on top of the image */
}

.family-vines-img{
    height: 100%;
    width: 100vw;
    object-fit: cover;

}

.lamp-img{
    margin-top: 70px;
    height: 100%;
    width: 100vw;
}

.family-lamps{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    text-align: center;
}

.family-heading{
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    top: 1250px;
    z-index: 5;
    color: #f2f2eb;
    font-family: KumbhSans;
    font-weight: 100;
    font-size: 42px;
}

.family-p{
     position: absolute;
     text-align: justify;
     left: 0;
     right: 0;
     margin: auto;
     /*width: fit-content;*/
     top: 1425px;
     font-size: 20px;
     width: 590px;
     z-index: 5;
     color: white;
    /*background-color: rgba(37, 36, 34, 0.6);*/
     font-family: KumbhSans;
     font-weight: 200;

}

.family-p2{
    position: absolute;
    text-align: justify;
    left: 0;
    right: 0;
    margin: auto;
    /*background-color: rgba(37, 36, 34, 0.6);*/
    /*width: fit-content;*/
    top: 1502px;
    font-size: 20px;
    width: 590px;
    z-index: 5;
    color: white;
    font-family: KumbhSans;
    font-weight: 200;

}

.family-p3{
    position: absolute;
    text-align: justify;
    left: 0;
    right: 0;
    margin: auto;
    /*width: fit-content;*/
    top: 1395px;
    font-size: 20px;
    width: 510px;
    z-index: 5;
    color: white;
    font-family: KumbhSans;
    font-weight: 200;

}


.family-decoration-line{
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    position: absolute;
    z-index: 3;
    height: 1px;
    top: 1810px;
    background-color: #f2f2eb;
}

.family-decoration-line-bottom{
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    position: absolute;
    height: 1px;
    top: 2920px;
    background-color: #f2f2eb;
}

.family-image{
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 1;
    top: 1730px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.map-container{
    /*border-top: solid #090909 5px;*/
    border-bottom: solid #090909 8px;
    height: 100%;
    width: 100%;
    /*background-image: url("../assets/product-images-webp/MAMA-TA.webp");*/
    background-color: #252422;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.map-container2{*/
/*    !*border-top: solid #090909 5px;*!*/
/*    border-bottom: solid #090909 8px;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*}*/

.heading-container{
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.heading-map{
    font-family: KumbhSans;
    font-weight: 550;
    color: #f2f2eb;
    font-size: 36px;
    margin-right: 20px;
    margin-top: 30px;
}

.heading-map1{
    font-family: KumbhSans;
    font-weight: 550;
    color: #f2f2eb;
    font-size: 36px;
    margin-right: 20px;
    margin-top: 30px;
}

.heading-heart{
    margin-top: -12px;
    height: 48px;
}

.heading-heart1{
    margin-top: 5px;
    height: 46px;
}

.map-asset-container{
    height: 500px;
    /*background-color: #1c6c1c;*/
    width: 60%;
    /*filter: grayscale(100%) invert(90%) contrast(92%);*/
}

/*.map-border{*/
/*    border: 3px solid #f2f2eb;*/
/*    width: 60%;*/
/*}*/

.map-asset-container1{
    margin-top: 80px;
    display: none;
    height: 500px;
    /*background-color: #1c6c1c;*/
    border: solid 2px #f2f2eb;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.maps-container{

    justify-content: space-evenly;
    margin-top: 10px;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
}

.map-google{
    height: 500px;
    width: 100%;
    border: none;
}

.address-detail-container{
    padding-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.address-detail{
    margin-top: -40px;
    color: #f2f2eb;
    font-family: KumbhSans;
    font-size: 22px;
    margin-left: 10px;
    width: 40%;
}

.circle-decorator{
     height: 20px;
     background-color: #f2f2eb;
     width: 20px;
    margin-top: 3px;
    margin-right: 12px;
    border-radius: 50%;
}

.address-p-container{
      display: flex;
      justify-content: center;
    margin-bottom: 10px;
}

.p-address{
    margin: 0;
    padding: 0;
    width: 220px;
}

.vertical-dec{
    /*width: 1px;*/
    /*background-color: #f2f2eb;*/
    height: 100px;
    width: 10px;
}

.address-detail-mobile{
    display: none;
}

.despre-noi-heading{
    height: 44px;
    border-radius: 25px;
    position: absolute;
    z-index: 3;
    top: 1910px;
    width: 500px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #f2f2eb;
    text-align: center;
}

.citate-about-us{
    color: #f2f2eb;
    font-size: 20px;
    font-family: KumbhSans;
    text-align: center;
    margin-top: 8px;
}


.citate-about-us-top{
    position: absolute;
    color: #f2f2eb;
    font-size: 20px;
    font-family: KumbhSans;
    text-align: center;
    margin-top: 8px;
    z-index: 1;
    top: 1850px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.review-card{
    height: 380px;
    width: 30%;
    padding: 20px;
    background-color: #252422;
    margin: 20px;
    border-radius: 20px;
    border: solid #090909 2px;
    font-family: KumbhSans;
    font-size: 18px;
    color: #f2f2eb;
}

.review-name{
    text-align: center;
    margin-bottom: 0;
    margin-top: 6px;
    margin-left: 20px;
    font-size: 20px;
}

.review-text{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
}

.profile-picture{
    height: 40px;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.review-stars{
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.logo-green-rating{
    height: 28px;
    margin-right: 4px;
}


.review-date-container-pc{
    margin-top: 12px;
}

.review-date{
    margin-top: 5px;
}

.review-text{
    text-align: left;
    width: 100%;
    padding: 0;
    margin-left: 0;
    font-size: 18px;
}


.review-carousel-container{
    margin-left: 3%;
    margin-right: 3%;
    /*margin-top: 20px;*/
    display: flex;
}

.heading-map-down{
    text-align: center;
    font-size: 30px;
    color: #f2f2eb;
    font-family: KumbhSans;
}

.reviews-maps-top{
    display: flex;

}

.review-date-container{
    display: inline-block;
    margin-top: 12px;
}

.review-asset{
    width: 170px;
}

.review-asset-rate{
    height: 48px;
    width: 48px;
}


.rate-us-p{
    font-size: 36px;
    color: #f2f2eb;
    font-family: KumbhSans;
    margin-right: 15px;
    font-weight: 400;
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
}

.show-form-button {
    margin-top: 100px;
    padding: 15.5px 20px;
    width: 91%;
    background-color: #252422;
    border-radius: 15px;
    border: solid #090909 2px;
    color: white;
    cursor: pointer;
    font-family: KumbhSans;
    font-size: 36px;
}

.contact-form {
    /*margin-top: 400px;*/
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left:5%;
    margin-right: 5%;
    background-color: #252422;
    border-radius: 5px;
    position: relative;
    border: solid #090909 2px;

}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
}

.input-contact{
    border: 2px solid #30820B;
    font-family: KumbhSans;

}

.form-group:last-child {
    margin-right: 0;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    background-color: #252422;
    color: #f2f2eb;
    font-size: 16px;
}



.full-width {
    flex: 1 0 100%;
    margin-bottom: 15px;
    border-radius: 8px;
    margin-right: 0px;
}

.contact-heading{
    text-align: center;
    padding: 20px;
    margin-bottom: 25px;
    font-family: KumbhSans;
    color: #f2f2eb;
    font-size: 22px;
}



button[type="submit"] {
    padding: 10px 20px;
    background-color: #30820B;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

button[type="submit"]:hover {
    background-color: #30820B;
}


.form-group textarea{
    padding: 10px;
    font-size: 14px;
    border: 2px solid #30820B;
    border-radius: 4px;
    background-color: #252422;
    color: #f2f2eb;
    font-size: 16px;
    border-radius: 8px;
}

textarea{
    width: 100%; /* Make input and textarea fit the container width */
    max-width: 100%; /* Prevent input fields from growing beyond their container */
    box-sizing: border-box;
}

/* X button styling */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #f2f2eb;
}

.close-button:hover {
    color: #30820B;
}

label{
    font-family: KumbhSans;
    font-size: 18px;
    color: #f2f2eb;
    padding-bottom: 5px;
}


input{
    border: 1px solid #1c6c1c;
}

.contact-warning-container{
    height: 120%;
    margin-bottom: 50px;
    border: 2px solid #30820B;
    border-radius: 8px;
    background-color: #232B22;
    color: #f2f2eb;
}

.contact-warning-p{
    font-family: KumbhSans;
    padding-left: 15px;
    margin-top: 10px;
}



.contact-warning-li{
    font-family: KumbhSans;
    color: #f2f2eb;
    margin-left: 25px;
    margin-bottom: 10px;
}

.button-submit{
    width: 100%;
    margin-bottom: 25px;
}

.contact-container{
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
}

.long-rope{
    display: none;
}

.div-fill{
    display: none;
}

.rate-us-container{
    border-radius: 15px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    /*background-color: #252422;*/
    /*border: solid #090909 2px;*/
    width: 91%;
    margin: 70px 4.5% 60px;
}


@media (max-width: 600px) {
    .lamp-img{
        margin-top: -50px;
    }
}

@media (max-width: 768px) {

    .div-fill{
        display: flex;
        height: 700px;
        width: 100%;
    }


    .top-black-screen{
        background-image: url('../assets/product-images-webp/imimanancaRAMII.webp');
        background-position: 50% 40%;
        background-size: cover;
        height: 885px;
        margin-top: 50px;
        width: 100%;
    }

    .family-vines-img{
        height: 100%;
        width: 100vw;
        object-fit: cover;
        margin-top: 75px;
    }

    .family-container {
        height: 1120px;
    }


    .family-heading{
        font-size: 32px;
        width: 100%;
        margin-top: -40px;
    }

    .family-heading-top{
        margin-top: 150px;
        font-size: 33px;
    }

    .family-p{
        font-size: 20px;
        width: 310px;
        margin-top: -80px;
        line-height: 23px;
        z-index: 3;
    }

    .family-p2{
        font-size: 20px;
        width: 310px;
        line-height: 23px;
        margin-top: -15px;
        z-index: 3;
    }

    .family-p3{
        font-size: 20px;
        width: 310px;
        line-height: 23px;
        margin-top: 126px;
    }

    .family-decoration-line{
        margin: 0;
        width: 100%;
        position: absolute;
        height: 1px;
        top: 1790px;
        background-color: #f2f2eb;
    }


    .family-image{
        margin-top: -10px;
    }


    .maps-container{
        justify-content: space-evenly;
        margin-top: 60px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        display: inline-block;
        background-image: none;
    }

    .map-asset-container{
        display: flex;
        /*background-color: #1c6c1c;*/
        border: solid 2px #f2f2eb;
        width: 80%;
        margin: 80px 10%;
    }

    .map-asset-container1{
        margin-bottom: 80px;
        display: flex;
        height: 500px;
        border: solid 2px #f2f2eb;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .p-address{
        font-size: 24px;
        width: 250px;
        margin-right: -30px;
        margin-left: 10px;
    }

    .circle-decorator{
        margin-top: 5px;
    }

    .map-google{
        height: 400px;
        width: 100%;
        border: none;
    }

    .map-container{
        border: none;
        height: 100%;
        width: 100%;
    }


    .address-detail-container{
        display: none;
    }

    .address-detail-mobile{
        margin-top: 100px;
        display: initial;
        color: #f2f2eb;
        font-family: KumbhSans;
        font-size: 22px;
        margin-bottom: 400px;
    }

    .heading-container{
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        padding-top: 10px;
        margin-bottom: -60px;
        display: flex;
        align-items: center;

    }

    .heading-map{
        font-size: 30px;
        margin-right: 10px;
        /*width: 60%;*/
    }

    .heading-map1{
        font-size: 30px;
        margin-right: 10px;
        /*width: 60%;*/
    }

    .heading-heart{
        margin-top: -2px;
        height: 40px;
    }

    .heading-heart1{
        margin-top: 18px;
        height: 80px;
    }

    .family-decoration-line-bottom{
        display: none;
    }

    .review-carousel-container{
        display: none;
        /*width: 100%;*/
        /*margin-top: 80px;*/
    }

    .reviews-maps-top{
        display: flex;

    }

    .review-card{
        width: 290px;
        height: 200px;
        padding: 25px;
        background-color: #f2f2eb;
        margin: 20px;
        border-radius: 20px;
        border: solid black 6px;
        font-family: KumbhSans;
        font-size: 18px;
    }

    .review-name{
        text-align: center;
        margin-bottom: 0;
        margin-top: 6px;
        margin-left: 20px;
    }

    .review-text{
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
    }

    .profile-picture{
        height: 40px;
        border-radius: 20px;
        text-align: center;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .review-stars{
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .review-date-container{
        display: flex;
        margin-top: 12px;
    }

    .review-text{
        text-align: left;
        padding: 0;
        margin-left: 0;
    }

    .despre-noi-heading{
        height: 40px;
        border-radius: 25px;
        position: absolute;
        z-index: 1;
        top: 1880px;
        width: 90%;
        border: 1px solid #f2f2eb;
        margin-right: 5%;
    }

    .citate-about-us{
        color: #f2f2eb;
        font-size: 17px;
        font-family: KumbhSans;
        text-align: center;
        margin-top: 8px;
    }

    .citate-about-us-top{
        position: absolute;
        color: #f2f2eb;
        font-size: 18px;
        font-family: KumbhSans;
        text-align: center;
        margin-top: 8px;
        z-index: 1;
        top: 1815px;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .rate-us-container{
        height: 125px;
        position: absolute;
        border-radius: 25px;
        top: 4950px;
        border: solid #090909 2px;
        display: inline-block;
        justify-content: center;
        width: 80%;
        margin-right: calc(10% - 2px);
        margin-left: calc(10% - 2px);
        align-items: center;
        background-color: #252422;
        z-index: 5;
    }

    .rating-asset-container{
        display: flex;
        justify-content: center;
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
    }

    .rate-us-p{
        margin: 0;
        padding: 10px;
        font-size: 32px;
        text-align: center;
        z-index: 2;
    }

    .contact-container{
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;

    }

    .contact-form-container{
        margin-top: 360px;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .contact-form{
        z-index: 3;
        width: 100%;
        margin-top: 80px;
    }

    .contact-warning-container{
        height: 280px;
    }

    .contact-warning-li{
        margin-right: 5%;
    }

    .row{
        width: 100%;
        display: inline-block;
    }

    .contact-label{
        margin-top: 10px;
    }

    .form-group{
        margin: 0;
    }

    .button-submit{
        margin-top: 20px;
    }

    .show-form-button{
        font-size: 28px;
        width: 100%;
        margin: 140px;
        z-index: 2;
    }

    .long-rope{
        display: flex;
        position: absolute;
        top: 4350px;
        height: 1115px;
        width: 30px;
        object-fit: cover;
        left: calc(50% - 30px);
        z-index: 1;
    }

    .slider-container{
        top: 4350px;
        background-color: #252422;
        z-index: 3;
        display: flex;
        position: absolute;
        height: 500px;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        border-radius: 25px;
        border: solid #090909 2px;
    }


    /*label{*/
    /*    text-align: center;*/
    /*}*/

    /*input{*/
    /*    text-align: center;*/
    /*}*/
    .each-slide {
        /*position: relative;*/
        /*width: 300px;*/
        /*height: 500px;*/
        /*background-size: cover;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .wrapper {
        display: flex;
        width: 100vw;
        overflow: hidden;
        transition: transform 0.7s ease-in-out;
    }

    .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        flex-shrink: 0;
    }

    .slide-content {
        /*background: rgba(0, 0, 0, 0.6);*/
        padding: 20px;
        border-radius: 10px;
        color: white;
        text-align: center;
        width: 80%;
        margin-right: 20%;
        height: 500px;
        font-family: KumbhSans;

    }

    .review-text {
        font-size: 20px;
        margin-bottom: 10px;
        width: 80%;
        margin-left: 10%;
    }

    .review-details {
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-left: 10%;
        margin-right: 10%;
    }

    .profile-picture{
    }

    .review-name {
        font-size: 22px;
        margin-left: 20px;
    }

    .review-date {
        font-style: italic;
    }

    .rating-green-cont{
        margin-top: 18px;
        text-align: left;
        margin-left: 10%;
    }

    .logo-green-rating{
        height: 30px;
        margin-right: 4px;
    }

    .date-review-mobile{
        font-size: 18px;
        margin-top: 20px;
        text-align: left;
        margin-left: 10%;
    }

    .decoration-bottom-black{
        /*position: absolute;*/
        /*width: 100%;*/
        /*height: 2px;*/
        /*background-color: #090909;*/
        /*top: 5285px;*/
        display: none;
    }

    .arrow-left{
        rotate: -180deg;
        margin-left: 15px;
    }

    .arrow-right{
        margin-right: 15px;
    }


}

@media (min-width: 1000px) {
    .lamp-img{
        margin-top: 130px;
    }
}

@media (max-width: 361px) {
    .citate-about-us{
        color: #f2f2eb;
        font-size: 16px;
        font-family: KumbhSans;
        text-align: center;
        margin-top: 8px;
    }
}

