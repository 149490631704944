.grid-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 0;
    width: 100%;
    box-sizing: border-box;
}

.menu-heading{
    text-align: center;
    font-family: KumbhSans;
    font-size: 30px;
    margin: 0px 15px 15px;
    color: #f2f2eb;
}


.product-heading-container {
    position: sticky;
    top: 40px; /* Stick to the top of the viewport */
    width: 100%;
    z-index: 10; /* Ensure it stays on top of other content */
    /*padding-top: 100px;*/
    /*padding-bottom: 45px;*/
}

.product-heading-title {
    width: 100%;
    color: #ffffff;
    font-family: KumbhSans;
    font-size: 44px;
    text-align: center;
    /* Adjust if necessary */
    margin: 0
    /*margin: 0 0 0 9%;*/
}

/* Decorative line under the heading */
.product-heading-decoration {
    width: 50px;
    height: 3px;
    background-color: #ffffff; /* Adjust color if necessary */
    margin: 10px auto 0 auto; /* Center align and space from title */
}


/* Outer container to center the grid and manage space for the sidebar */
.product-row-grid {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px; /* Optional: Set a max width for better centering */
    margin: 0 auto;
    padding: 0 20px; /* Ensure some padding for the overall layout */
}

/* Sidebar (filter dropdown) */
.sidebar {
    width: 180px;
    margin-right: 20px;
    margin-left: 20px;
    position: sticky;
    text-align: center;
    height: 498px;
    top: 100px; /* Adjust this based on your header height if needed */
    display: none; /* Hidden by default */
    background: rgba(48, 130, 11, 0.5); /* Background for better visibility */
    border: 2px solid #30820B;
    padding: 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Add shadow for better separation */
}

ul{
    margin: 0;
    list-style: none;
    background-color: transparent;
    font-family: KumbhSans;
    font-weight: 600;
    width: 180px;
    border: none;
    padding: 0;
}

li{
    color: white;
    background-color: transparent;
    border: none;
}

.button-category {
    height: 55px;
    width: 180px;
    background-color: transparent;
    border: 2px solid transparent;
    color: #ffffff;
    font-family: KumbhSans;
    font-weight: 800;
    font-size: 18px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Hover effect for buttons within .button-category */
.button-category:hover {
    background-color: #30820b;
    color: #ffffff;
}

/* Styling for the active category button */
.button-category.active {
    background-color: #30820b;
    color: #ffffff;
}


.button-close{
    top: 100px;
    position: sticky;
    width: 40px;
    height: 40px;
    /*margin-left: 20px;*/
    display: none; /* Hidden by default */
    background-image: url("../assets/arrowClose.svg");
}

.sidebar-label {
    display: block;
    font-size: 18px;
    font-family: KumbhSans;
    margin-bottom: 10px;
}

.sidebar-selection {
    width: 100%;
    padding: 5px 10px;
    font-size: 18px;
    font-family: KumbhSans;
    background-color: #fff;
}

/* Product grid layout */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Minimum 350px per item */
    gap: 50px; /* Keep the gap between columns consistent */
    width: 100%;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
}

/* Display the sidebar only on wider screens */
@media (min-width: 1450px) {

    .menu-picture{
        padding-top: 100px;
        height: 500px;
        width: 100%;
    }

    .menu-picture-img{
        object-fit: contain;
        height: 100%;
        width: 100%;
    }

    .sidebar {
        margin-top: 17px;
        border-radius: 2px;
        font-weight: 700;
        display: block;
        position: sticky;
    }

    .button-close {
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 20px;
        display: block;
        position: sticky;
        background-image: url("../assets/arrowClose.svg");
        background-color: transparent;
        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: none;
        border: none;
        height: 50px;
        width: 50px;
    }

    /* Product grid centered with consistent spacing */
    .product-grid {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 350px); /* Each item has a fixed width */
        gap: 40px; /* Consistent gap between items */
        justify-content: center; /* Center the grid within its container */
    }

    /* Outer container to center the grid and manage space for the sidebar */
    .product-row-grid {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        max-width: calc(4 * 350px + 3 * 50px + 240px); /* Adjust the max-width to fit the grid and sidebar */
        width: 100%;
        margin: 0 auto;
        padding: 0 20px; /* Ensure some padding for the overall layout */
    }

    .padding-top-div{
        padding-top: 100px;
    }
}



@media (max-width: 1450px) {
    /* Flex container for centering the grid horizontally */
    .grid-wrapper {
        display: flex;
        justify-content: center; /* Center the grid container horizontally */
        padding-top: 0;
    }


    /* Main product grid */
    .product-grid {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 350px); /* Fixed width for each product */
        gap: 50px;
        justify-content: center; /* Center the items within the grid */
    }

    .product-row-grid {
        display: flex;
        width: 100%;
    }
}

.mobile-dropdown-container {
    display: none; /* Default hidden */
    position: fixed; /* Fixed position relative to the viewport */
    top: 45px; /* Adjust this based on your navbar height */
    left: 0; /* Align to the left edge */
    right: 0; /* Align to the right edge */
    margin: auto; /* Center horizontally */
    width: calc(100% - 2px);
    z-index: 2; /* Ensure it stays on top of other elements */
    border: 1px solid #f2f2eb;
}

.mobile-dropdown-container.show{
    border: 2px solid #1c6c1c;
    width: calc(100% - 4px);
    top: 45px; /* Adjust this based on your navbar height */
}

.mobile-dropdown-toggle {
    position: sticky; /* Keeps the button sticky if needed */
    top: 0; /* Stick to the top of its container */
    width: 100%;
    margin: 2px auto 0;
    height: 60px;
    font-size: 18px;
    font-family: KumbhSans;
    font-weight: 700;
    color: #ffffff;
    background-color: #252422;
    border: 0;
    cursor: pointer;
    z-index: 4; /* Ensure it's above other content */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effects */
}


.mobile-dropdown {
    display: flex;
    list-style: none;
    padding: 0;
    border: 2px solid #1c6c1c;
    width: calc(100% - 0px);
    margin: 0;
    background-color: rgb(43, 82, 21);
    position: absolute; /* Absolute positioning relative to container */
    left: 50%;
    top: 58px; /* Position below the toggle button */
    transform: translateX(-50%); /* Center horizontally */
    visibility: hidden; /* Initially not visible */
    transition: opacity 0.4s ease, visibility 0.4s ease;
    opacity: 0; /* Initially hidden */
    height: 102vh; /* Set fixed height */
    overflow-y: auto; /* Allow scrolling if content is too tall */
    z-index: 3;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
}

.mobile-dropdown.show {
    top: 59px;
    visibility: visible; /* Make it visible */
    transform: translateX(-50%) translateY(0); /* Move to visible position */
    opacity: 1; /* Fully visible */
}

.mobile-dropdown li {
    width: 100%;
    margin: 0;
    height: 55px;
}

.button-category {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: #ffffff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
}

.arrow-toggle-mobile{
    height: 20px;
    width: 30px;
    padding-left: 30px;
}



@media (max-width: 1450px) {
    .mobile-dropdown-container {
        display: block;
    }
    /* Hide the sidebar on mobile devices */
    .sidebar {
        display: none;
    }

    .padding-top-div{
        padding-top: 130px;
    }

    .menu-picture{
        padding-top: 100px;
        height: 500px;
        width: 100%;
    }

    .menu-picture-img{
        object-fit: contain;
        height: 100%;
        width: 100%;
    }


}

@media (max-width: 900px) {
    .menu-picture{
        height: 300px;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .menu-picture-img{
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
}

