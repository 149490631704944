/* Navbar container */
.navbar {
    padding: 10px 20px;
    background-color: #f2f2eb;
    color: #000000;
    position: fixed;
    top: 0;
    height: 30px;
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out; /* Smooth transition for all properties */
}

/* Header section containing the logo and toggle button */
.navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Logo styles */
.navbar-logo {
    flex: 1;
}

.navbar-logo img {
    margin-top: 3px;
    width: 165px;
    height: 30px;
}

/* Links container for desktop */
.navbar-links {
    display: flex;
    justify-content: flex-end;
    flex: 3;
    white-space: nowrap; /* Prevent links from wrapping onto multiple lines */
    margin-right: 70px;
    font-family: KumbhSans;
    font-size: 16px;
    font-weight: 350;
}

.navbar-links a {
    color: #000000;
    text-decoration: none;
    padding: 0 15px;
    font-size: 19px;
    border-right: 1px solid black;
    transition: color 0.3s ease, border-color 0.3s ease; /* Smooth transition for color */
}

.navbar-links a:hover {
    text-decoration: none;
    color: #555; /* Change color on hover */
}

/* Toggle button (hidden on desktop) */
.navbar-toggle {
    width: 30px;
    height: 15px;
    background-size: cover; /* Ensures the image covers the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image within the element */
    background-image: url("./assets/HamburgerMenu.svg");
    display: none;
    background-color: transparent;
    border: none;
    color: #000000;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease; /* Smooth transition for toggle button color */
}

/* Mobile styles */
@media (max-width: 768px) {
    .navbar {
        flex-direction: row;
        align-items: center;
        padding: 10px;
        z-index: 10;
    }

    .navbar-header {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-logo {
        width: auto;
    }

    .navbar-img{
        margin-left: 0px;
        margin-top: 2px;
        height: 10px;
    }

    .navbar-toggle {
        width: 20px;
        height: 20px; /* Adjust height for better visibility */
        background-image: url("./assets/HamburgerMenu.svg");
        background-size: contain; /* Make sure the image fits within the button */
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: transform 0.3s ease;
        margin-right: 20px;
        z-index: 103; /* Ensure it appears above other elements */
    }


    .navbar-links {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh; /* Full viewport height */
        max-height: 0; /* Start with max-height 0 for transition */
        background-color: rgb(0, 0, 0);
        position: fixed;
        top: 45px; /* Adjust this based on the height of your navbar */
        left: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 2;
        opacity: 0;
        overflow: hidden;
        transition: max-height 0.5s ease, opacity 0.5s ease; /* Smooth transition */
    }

    .navbar-links.open {
        max-height: calc(100vh - 40px); /* Expand to full screen height minus the navbar */
        opacity: 1; /* Fade in */
    }

    .navbar-links a {
        padding: 20px 0;
        width: 100%;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 1.5em;
        transition: background-color 0.3s ease; /* Smooth transition for background color */
        text-decoration: none;
    }

    .navbar-links a:hover {
        background-color: rgb(242, 242, 235);
        color: #252422;
        text-decoration: none;
        font-size: 24px;
    }
}
