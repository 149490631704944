.prelucrareaDatelor-container{
    height: 100%;
    background-color: #252422;
    text-align: center;
}

.date-p{
    padding-top: 80px;
    font-family: KumbhSans;
    font-size: 40px;
    color: #f2f2eb;
}

.prelucrare-texte{
    font-family: KumbhSans;
    font-size: 20px;
    color: #f2f2eb;
    text-align: left;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}