.heading-details{
    padding-top: 20px;
    width: 88%;
    display: flex;
    justify-content: space-between;
}

.flex-dropdown-active{
    display: flex;
    margin-right: 80px;
    height: 10px;
}




/*.product-description {*/
/*    max-height: 0;*/
/*    overflow: hidden;*/
/*    !*transition: max-height 0.5s ease, opacity 0.3s ease, margin-top 0.3s ease; !* Smooth transitions *!*!*/

/*}*/

/*.product-description-visible {*/
/*    opacity: 1;*/
/*    max-height: 200px;*/
/*    margin-top: 10px;*/
/*}*/

/*.product-description.product-description-visible {*/
/*    transition: max-height 0.5s ease, opacity 0.3s ease-in, margin-top 0.3s ease-in;*/
/*}*/


/* Styling for buttons within .button-category */



.product-description {
    font-size: 1em;
    color: #000000;
    text-align: left;
    margin: 2px 120px 2px 10px;
    font-family: KumbhSans;
    font-weight: 550;
}



.decoration-line-top {
    background-image: url("../assets/Line 1.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left;
    width: 202px;
    height: 2px;
    margin-top: 5px;
    margin-right: 76px;
    box-sizing: border-box;
}

.arrow-dec{
    width: 16px;
    height: 16px;
}

.decoration-flex{
    display: flex;
    width: 202px;
    margin-right: 78px;
}

.arrow-dec.active{
    opacity: 0;

}


.decoration-line-top2.active{
    opacity: 0;
}


/*.arrow-dec{*/
/*    opacity: 1;*/
/*    transition: opacity 0.3s ease-in;*/
/*}*/

/*.decoration-line-top2{*/
/*    opacity: 1;*/
/*    transition: opacity 0.3s ease-in;*/
/*}*/

.decoration-line-top2 {
    background-image: url("../assets/Dropdown upper line.svg");
    background-size: cover; /* Ensures the image covers the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image within the element */
    width: 180px; /* Make the div stretch to the full width of its container */
    height: 2px; /* Set the height of the div */
    margin-top: 5px; /* Adjust margin if necessary */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    margin-right: 17px;
}


.decoration-line-bottom {
    background-image: url("../assets/Dropdown CLOSED.svg"); /* Your background image */
    background-size: cover; /* Ensures the image covers the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: center; /* Centers the image within the element */
    width: 202px; /* Make the div stretch to the full width of its container */
    height: 10px; /* Set the height of the div */
    margin-top: 2px; /* Adjust margin if necessary */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    margin-right: 77px;
}



.decoration-line-bottom-active{
    background-image: url("../assets/Dropdown OPEN.svg"); /* Your background image */
    background-size: cover; /* Ensures the image covers the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    width: 200px; /* Make the div stretch to the full width of its container */
    height: 12px;
    margin-top: 2px; /* Adjust margin if necessary */
    margin-right: 30px;
    margin-left: 10px;
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.logo-bottom{
    /*top: 350px;*/
    /*left: 78%;*/
    margin-left: 240px;
    height: 35px;
    width: 48px;
    /*position: absolute;*/
}

/*.logo-bottom-active{*/
/*    top: 435px;*/
/*    left: 78%;*/
/*    height: 40px;*/
/*    width: 40px;*/
/*    position: absolute;*/
/*}*/

.bottom-flex{
    display: inline-flex;
}



.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    padding: 15px 15px 50px;
    background-color: #f2f2eb;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    height: 102%;
    /*transition: height 0.7s ease, width 0.7s ease, padding 0.7s ease;*/
    position: relative;
}


.logo-bottom {
    position: absolute;
    padding-top: 20px;
    bottom: 10px;
    right: 35px;
    height: 35px;
    width: 48px;
    transition: 0.15s ease;
}



.product-image {
    width: 280px;
    height: 280px;
    margin-bottom: 10px;
    object-fit: cover;
    box-sizing: border-box;
    transition: none;
}


.product-image-container {
    width: 280px;
    height: 280px;
    overflow: hidden;
    position: relative;
}



.product-name {
    font-size: 1.2em;
    margin: 0;
    font-family: KumbhSans;
    font-weight: 600;
    text-align: left;
    width: 70%;
}

.product-price {
    font-size: 1.2em;
    color: #000000;
    margin: 0;
    font-weight: 600;
    font-family: KumbhSans;
}







