html, body, #root {
  height: 100%;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}


@font-face {
  font-family: 'MyCustomFont';
  src: url('./fonts/KastelovAxiformaLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.App {
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url('./assets/bg.svg') no-repeat center center;
  background-size: cover;
}





.hero-p-brew{
  color: white;
  font-family: "Cabin", sans-serif;
  font-style: normal;
  font-size: 24px;
}

.hero-container{
  margin-left: 10%;
  margin-right: 10%;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-out {
  animation: fadeOut 1.5s forwards;
}

.fade-in {
  animation: fadeIn 1.5s forwards;
}



.content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
}

.media-icons{
  padding-top: 7vh;
  justify-content: space-around;
}

.social-icon{
  padding: 15px;
  height: 60px;
  width: 60px;
  margin-left: 5px;
  margin-right: 5px;
}

.heart-img{
  margin-top: 5px;
  height: 25px;
  margin-left: 15px;
}

.justify{
  justify-content: center;
  display: flex;
}

@media (min-width: 801px) and (max-width: 1200px){
  .hero-heading{
    font-size: 24px;
  }
}


@media (max-width: 800px) {

  .hero-p{
    padding-top: 8vh;
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-top: 8vh;
    margin-left: 15px;
    margin-right: 15px;
  }

  .hero-p-j{
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    margin: 8px 0px;
    letter-spacing: 0.5px;
  }

  .hero-heading{
    padding-top: 24vh;
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    letter-spacing: 3px;
    font-weight: 300;
    font-size: 27px;
    margin: 0 20px;
  }

  .media-icons{
    padding-top: 7vh;
    justify-content: space-around;
  }

}

@media  (max-width: 1000px) and (orientation: landscape)  {
  .hero-p, .hero-p-j{
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    margin: 8px;
  }

  .hero-heading{
    padding-top: 20vh;
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    letter-spacing: 3px;
    font-weight: 420;
    font-size: 28px;
    margin: 0 20px;
  }

  .hero-p{
    padding-top: 0vh;
    margin-top: 8vh;
  }

  .media-icons{
    padding-top: 2vh;
    justify-content: space-around;
  }
}

@media (min-width: 1000px) {

  .hero-p, .hero-p-j{
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    margin: 8px;
  }

  .hero-p{
    padding-top: 15vh;
    margin-top: 8vh;
  }

  .hero-heading{
    padding-top: 28vh;
    color: white;
    font-family: "Inter", sans-serif;
    font-style: normal;
    letter-spacing: 3px;
    font-weight: 420;
    font-size: 28px;
    margin: 0 20px;
  }


}



