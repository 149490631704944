.wrapper

  width: 300px
  display: block
  text-align: center
  transition: 0.4s ease
  &.active

    .arrow
      transform: rotate(45deg) translate(-5px,-5px)
      &:before
        transform: translate(10px,0)
      &:after
        transform: rotate(90deg) translate(10px,0)
    .div-desc
      margin-left: 35px
      opacity: 1
      overflow: hidden
    .product-description
      margin-right: 20px
      margin-bottom: 10px





@media (max-width: 768px)
.wrapper
  margin: 0
  &.active
    margin-left: -65px
    width: 300px





.arrow
  width: 10px
  height: 10px
  display: inline-block
  position: relative
  bottom: -5px
  left: -80px
  transition: 0.4s ease
  margin-top: -3px
  text-align: left
  transform: rotate(45deg)
  float: right
  &:after
    position: absolute
    transform: rotate(90deg)
    top: -5px
    left: 5px

.click-text
  display: block
  text-align: left
  padding: 0 12px 0px
  margin: 0
  cursor: pointer
  color: rgba(0, 0, 0, 0.72)
  //transition: 0.15s ease
  &:hover
    color: rgb(0, 0, 0)


.logo-bottom
  transition: 0.15s ease

.div-desc
  padding: 0
  margin: 0
  height: 0
  overflow: hidden
  opacity: 0
  transition: height 0.4s ease, padding 0.4s ease, margin 0.4s ease
  color: #252422




